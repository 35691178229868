import $ from 'jquery';

module.exports = {
    track: (e) => {
        let url = e.href;
        gtag('event', 'clic', {
          'event_category': 'socials-buttons',
          'event_label': url
        });
    },
    refresh_form: () => {
      window.TransportNow.refresh_input("name");
      window.TransportNow.refresh_input("surname");
      window.TransportNow.refresh_input("email");
      window.TransportNow.refresh_input("subject");
      window.TransportNow.refresh_input("message");

      if ($("#name").hasClass("green") &&
        $("#email").hasClass("green") &&
        $("#surname").hasClass("green") &&
        $("#subject").hasClass("green") &&
        $("#message").hasClass("green")) {
        $("#send").removeClass("disabled");
        $("#send").removeAttr("disabled");
      } else {
        $("#send").attr("disabled", true);
        $("#send").addClass("disabled");
      }
    },
    refresh_input: (input) => {
      if(input == "email"){
        var email = $("#email").val();
        var atpos = email.indexOf("@");
        var dotpos = email.lastIndexOf(".");
  
        if (email == null || email == "" || atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= email.length) {
          $("#email").removeClass("green");
          $("#email").addClass("red");
        } else {
          $("#email").removeClass("red");
          $("#email").addClass("green");
        }
      }else{
        var value = $("#" + input).val();
        if (value == null || value == "") {
          $("#" + input).removeClass("green");
          $("#" + input).addClass("red");
        } else {
          $("#" + input).removeClass("red");
          $("#" + input).addClass("green");
        }
      }
    },
    validateContactForm: () => {
      $("#name").on('keyup', function () {
        window.TransportNow.refresh_input("name");
        window.TransportNow.refresh_form();
      });
  
      $("#surname").on('keyup', function () {
        window.TransportNow.refresh_input("surname");
        window.TransportNow.refresh_form();
      });
  
      $("#email").on('keyup', function () {
        window.TransportNow.refresh_input("email");
        window.TransportNow.refresh_form();
      });
  
      $("#subject").on('keyup', function () {
        window.TransportNow.refresh_input("subject");
        window.TransportNow.refresh_form();
      });

      $("#message").on('keyup', function () {
        window.TransportNow.refresh_input("message");
        window.TransportNow.refresh_form();
      });
    },
    sendMessageAJAX: () => {
      var ERROR_MSG = "Une erreur est survenue. Merci de contacter un administrateur.";
      $("#send").on('click', function () {
        if ($(this).hasClass("disabled")) {
          $("#response_email").html("Merci de remplir tous les champs.");
        } else {
          var result = $("#response_email");
          $("#send").attr("disabled", true);
          $("#send").addClass("disabled");
          $("#icon-contact").show();
          $.ajax({
            url: "/send_email.php",
            type: "POST",
            data: {
              email: $("#email").val(),
              surname: $("#surname").val(),
              name: $("#name").val(),
              subject: $("#subject").val(),
              message: $("#message").val(),
            },
            dataType: "json",
            success: function (json, statut) {
              if (json.result.success) {
                result.html("<div class=\"alert alert-success\">" + json.result.success + "</div><br>");
                resetInput($("#email"));
                resetInput($("#surname"));
                resetInput($("#name"));
                resetInput($("#subject"));
                resetInput($("#message"));
                $("#icon-contact").hide();
              } else {
                result.html("<div class=\"alert alert-danger\">" + json.result.error + "</div><br>");
                $("#send").removeClass("disabled");
                $("#send").removeAttr("disabled");
                $("#icon-contact").hide();
              }
            },
            error: function () {
              result.html("<div class=\"alert alert-danger\">" + ERROR_MSG + "</div><br>");
              $("#send").removeClass("disabled");
              $("#send").removeAttr("disabled");
              $("#icon-contact").hide();
            }
          });
        }
      });
    },
    order: () => {
      $(".track-form").trigger("reset");
      var ERROR_MSG = "Une erreur est survenue. Merci de contacter un administrateur.";
      var step = 0;

      $(".track-form").submit(function (e) {
        e.preventDefault();
        $(this).find("#result").css("display", "inline");
        var result = $(this).find("#result");
        $("#finish-order").attr("disabled", true);
        $("#finish-order").addClass("disabled");
        $("#icon-order").show();
        $.ajax({
          url: "/send_order.php",
          type: "POST",
          data: {
            type: $("#type-order").val(),
            number: $("#number-order").val(),
            email: $("#email-order").val(),
            surname: $("#surname-order").val(),
            city: $("#city-order").val(),
            name: $("#name-order").val(),
            zipcode: $("#zipcode-order").val(),
            address1: $("#address1-order").val(),
            address2: $("#address2-order").val(),
            promoe: $("#promo").val(),
          },
          dataType: "json",
          success: function (json, statut) {
            if (json.result.success) {
              result.html("<div class=\"alert alert-success\">" + json.result.success + "</div><br>");
              resetInput($("#type-order"));
              resetInput($("#number-order"));
              resetInput($("#email-order"));
              resetInput($("#surname-order"));
              resetInput($("#city-order"));
              resetInput($("#name-order"));
              resetInput($("#zipcode-order"));
              resetInput($("#address1-order"));
              resetInput($("#address2-order"));
              resetInput($("#promo"));
              $("#icon-order").hide();
            } else {
              result.html("<div class=\"alert alert-danger\">" + json.result.error + "</div><br>");
              $("#finish-order").removeClass("disabled");
              $("#finish-order").removeAttr("disabled");
              $("#icon-order").hide();
            }
          },
          error: function () {
            result.html("<div class=\"alert alert-danger\">" + ERROR_MSG + "</div><br>");
            $("#finish-order").removeClass("disabled");
            $("#finish-order").removeAttr("disabled");
            $("#icon-order").hide();
          }
        });
      });

      $("#start-order").on('click', function () {
        if(!$("#start-order").hasClass("disabled")){
          step = 1;
          $("#step-0").hide();
          $("#step-1").show();
          $("#start").hide();
        }
      });
    
      $("#type-order").on('keyup', function () {
        var value = $("#type-order").val();
        if (value == null || value == "") {
          $("#type-order").addClass("red");
          $("#start-order").addClass("disabled");
          $("#start-order").attr("disabled", true);
        } else {
          $("#type-order").removeClass("red");
          $("#start-order").removeClass("disabled");
          $("#start-order").removeAttr("disabled");
        }
      });
    
      //step-1
    
      $("#name-order").on('keyup', function () {
        var value = $("#name-order").val();
        if (value == null || value == "") {
          $("#name-order").addClass("red");
          $("#name-order").removeClass("green");
        } else {
          $("#name-order").removeClass("red");
          $("#name-order").addClass("green");
        }
        step1form();
      });
    
      $("#surname-order").on('keyup', function () {
        var value = $("#surname-order").val();
        if (value == null || value == "") {
          $("#surname-order").addClass("red");
          $("#surname-order").removeClass("green");
        } else {
          $("#surname-order").removeClass("red");
          $("#surname-order").addClass("green");
        }
        step1form();
      });
    
      $("#email-order").on('keyup', function () {
        var email = $("#email-order").val();
        var atpos = email.indexOf("@");
        var dotpos = email.lastIndexOf(".");
    
        if (email == null || email == "" || atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= email.length) {
          $("#email-order").addClass("red");
          $("#email-order").removeClass("green");
        } else {
          $("#email-order").removeClass("red");
          $("#email-order").addClass("green");
        }
        step1form();
      });
    
      $("#number-order").on('keyup', function () {
        var value = $("#number-order").val();
        if (value == null || value == "") {
          $("#number-order").addClass("red");
          $("#number-order").removeClass("green");
        } else {
          $("#number-order").removeClass("red");
          $("#number-order").addClass("green");
        }
        step1form();
      });
    
      var step1form = function () {
        if ($("#name-order").hasClass("green") &&
          $("#email-order").hasClass("green") &&
          $("#surname-order").hasClass("green") &&
          $("#number-order").hasClass("green")) {
          $("#next-step").removeClass("disabled");
          $("#next-step").removeAttr("disabled");
        } else {
          $("#next-step").addClass("disabled");
          $("#next-step").attr("disabled", true);
        }
      };
    
      $("#next-step").on('click', function () {
        if(!$("#next-step").hasClass("disabled")){
          step = 2;
          $("#step-1").hide();
          $("#step-2").show();
        }
      });
    
      //step-2
      $("#city-order").on('keyup', function () {
        var value = $("#city-order").val();
        if (value == null || value == "") {
          $("#city-order").addClass("red");
          $("#city-order").removeClass("green");
        } else {
          $("#city-order").removeClass("red");
          $("#city-order").addClass("green");
        }
        step2form();
      });
    
      $("#zipcode-order").on('keyup', function () {
        var value = $("#zipcode-order").val();
        if (value == null || value == "") {
          $("#zipcode-order").addClass("red");
          $("#zipcode-order").removeClass("green");
        } else {
          $("#zipcode-order").removeClass("red");
          $("#zipcode-order").addClass("green");
        }
        step2form();
      });
    
      $("#address1-order").on('keyup', function () {
        var value = $("#address1-order").val();
        if (value == null || value == "") {
          $("#address1-order").addClass("red");
          $("#address1-order").removeClass("green");
        } else {
          $("#address1-order").removeClass("red");
          $("#address1-order").addClass("green");
        }
        step2form();
      });
    
    
      $("#address2-order").on('keyup', function () {
        var value = $("#address2-order").val();
        if (value == null || value == "") {
          $("#address2-order").addClass("red");
          $("#address2-order").removeClass("green");
        } else {
          $("#address2-order").removeClass("red");
          $("#address2-order").addClass("green");
        }
        step2form();
      });
    
      var step2form = function () {
        if ($("#zipcode-order").hasClass("green") &&
          $("#city-order").hasClass("green") &&
          $("#address1-order").hasClass("green") &&
          $("#address2-order").hasClass("green")) {
          $("#finish-order").removeClass("disabled");
          $("#finish-order").removeAttr("disabled");
        } else {
          $("#finish-order").addClass("disabled");
          $("#finish-order").attr("disabled", true);
        }
      };

      step1form();
      step2form();
    }
};