require('normalize.css/normalize.css');
require('./styles/index.scss');
import $ from 'jquery';
import TransportNow from './scripts/main';
window.TransportNow = TransportNow;
$(document).ready(() => {
    window.TransportNow.sendMessageAJAX();
    window.TransportNow.validateContactForm();
    window.TransportNow.refresh_form();
    window.TransportNow.order();
})